<template>
  <div class="panel-group-container">



            <div v-if="setbrandid === 'SV1'" class="panel-group">
        

            <div class="panel-row panel-row-3"><div class="panel-inner"><div class="panel image-right" style="width: 100%;"><div class="panel-data"><h3>Get more from your journey</h3><p style="width: 100%;">• A personalized
parking solution
near the Halifax
Stanfield
International Airport 
<br/>
• Includes a free
4-minute
shuttle to the
terminal door
<br/>
• Secure,
affordable
parking with
24/7 support<!-- {{ panel_data.brief_description }} --></p><a target="_blank" rel="noopener noreferrer" class="panel-data__button" href="https://www.summitairportparking.com/" data-v-d32d57f8="">Learn more</a></div><div class="panel-image" data-v-d32d57f8=""><!--v-if--></div></div><div class="panel no-image" data-v-d32d57f8="" data-v-093122d0=""><div class="panel-data" data-v-d32d57f8=""><h3 data-v-d32d57f8=""></h3><p data-v-d32d57f8=""><!-- {{ panel_data.brief_description }} --></p><!--v-if--></div><div class="panel-image" data-v-d32d57f8=""><img src="https://res.cloudinary.com/rezcomm2018/image/upload/f_auto,q_auto/v1713282019/brands/SV1/panels/1713282019_Map Rez.png/a28nzdwouz8clhmtkuqx.png" data-v-d32d57f8=""></div></div></div></div>
            </div>


    <div v-if="setbrandid === 'LDY'" class="panel-group">
      <div class="panel-row panel-row-3">
        <div class="panel-inner">
          <div
            class="panel image-right"
          >
            <div class="panel-data" >
              <h3>Secure parking</h3>
              <p> 
                <span>
                The car park will be locked and secured during periods when the airport is closed.
                Airport opening times (please note, these times are subject to change):</span>
                <table>
                    <tbody><tr>
                        <td>
                        Monday - Friday
                        </td>
                        <td>
                        <b>&nbsp;&nbsp;&nbsp;05:00 - 22:15</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Saturday
                        </td>
                        <td>
                        <b>&nbsp;&nbsp;&nbsp;05:00 - 18:00</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Sunday
                        </td>
                        <td>
                        <b>&nbsp;&nbsp;&nbsp;07:00 - 22:15</b>
                        </td>
                    </tr>
                </tbody></table>
                <br>
                <span>Christmas Day <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Closed</b></span>
                <br>
                <span>New Year's Day <b>&nbsp;&nbsp;&nbsp;05:00 - 09:00</b> </span>
                <br>
                <br>
                <span style="margin-top: 15px;">If you require out of hours access to retrieve your vehicle (see airport opening times), 
                please contact the Airport Customer Services on: <a class="BF-marketing-link" target="_blank" href="tel:028 7181 3302">028 7181 3302</a> during normal operating hours,
                in advance where possible, and we will plan to accommodate your request.</span>
            </p>
            <br><br>
            <!-- <h3>Booking Updates & Cancellations</h3>
            <p> 
            <span>
              Please ensure all details provided within your booking are correct at the time of booking. Any amendments to your booking will require the cancellation of the booking and a new booking will have to be placed - a new booking can be made on this website or directly with our Parking team at City of Derry Airport: <a class="BF-marketing-link" target="_blank" href="tel:028 7181 3302">parking@cityofderryairport.com.</a>
              </span>
            </p> -->
            <br><br>
            </div>
          </div>
        </div>
      </div>
    </div>


   <!--  <div class="announcement-banner" v-if="setbrandid === 'PIT'">
      Please be advised that Parking Lot 1 is currently closed, or a snowstorm has caused a blockage on certain roads within the site. We apologize for any inconvenience this may cause.
    </div> -->
    <div v-if="setbrandid === 'PIT'" class="panel-group-pit">
        <img width="560" height="auto" style="width: 100%; height: auto" src="https://res.cloudinary.com/rezcomm2018/image/upload/v1729775861/brands/PIT/REZ_Home_Page_Products-Map.jpg"/>
    </div>

    <div class="panel-group" v-if="setbrandid !== 'SV1' && setbrandid !== 'LDY' && setbrandid !== 'PIT'">
      <div
        v-for="(panel_row, i) in panels_group"
        :key="panel_row.row"
        :class="`panel-row panel-row-${i}`"
      >
        <div class="panel-inner">
          <template v-for="panel in panel_row.columns" :key="panel.panelid">
            <Panel
              :panel_data="panel"
              :lang="lang.toUpperCase()"
              :translations="translations"
              :brandSlug="setbrandid"
            />
          </template>
        </div>
      </div>
    </div>

    <div v-if="setbrandid === 'LAX'" class="lax-comp">
      <div class="lax-heading">Reasons to park with us</div>
      <div class="panel-group-lax">
        <div class="panel-lax">
          <img src="https://res.cloudinary.com/rezcomm2018/image/upload/v1737623342/brands/LAX/LAX_Flexible_Icon.png" alt="Flexibility">
          <div class="panel-lax-content">
            <p class="panel-lax-title">Flexibility</p>
            <p class="panel-lax-description">We allow you to change your reservation up to 2 hours before you fly.</p>
          </div>
        </div>
        <div class="panel-lax">
          <img src="https://res.cloudinary.com/rezcomm2018/image/upload/v1737623341/brands/LAX/LAX_Convenient_Icon.png" alt="No Reservation Fees">
          <div class="panel-lax-content">
            <p class="panel-lax-title">No Reservation Fees</p>
            <p class="panel-lax-description">The price you see is the price you'll pay. Taxes are included in the price you see.</p>
          </div>
        </div>
        <div class="panel-lax">
          <img src="https://res.cloudinary.com/rezcomm2018/image/upload/v1737623341/brands/LAX/LAX_Same_Day_Bookings_Icon.png" alt="Same Day Bookings">
          <div class="panel-lax-content">
            <p class="panel-lax-title">Same Day Bookings</p>
            <p class="panel-lax-description">We're go you covered for those last-minute trips.</p>
          </div>
        </div>
        <div class="panel-lax">
          <img src="https://res.cloudinary.com/rezcomm2018/image/upload/v1737623341/brands/LAX/LAX_Convenient_Icon.png" alt="Convenient">
          <div class="panel-lax-content">
            <p class="panel-lax-title">Convenient</p>
            <p class="panel-lax-description">We allow you to change your reservation up to 2 hours before you fly.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from "./Panel.vue";
export default {
  components: {
    Panel,
  },
  name: "PanelsGroup",
  props: {
    panels_group: undefined,
    lang: undefined,
    translations: undefined,
    setbrandid: undefined,
  },
};
</script>

<style scoped lang="scss">
.lax-comp{
  background-color: #f4f4f4;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.lax-heading {
  font-weight: bold;
  text-align: center;
  font-size: 28px;
}

.panel-group-lax {
  display: flex;
  max-width: 1300px;
  margin: auto;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .panel-group-lax {
    padding: 4rem;
  }
  .panel-lax {
    flex: 1 0 48%;
  }
}

@media screen and (min-width: 1024px) {
  .panel-group-lax {
    padding: 1rem;
  }
  .panel-lax {
    flex: 1 0 22%;
  }
}

.panel-lax {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel-lax img {
  padding: 10px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.panel-lax-content {
  display: flex;
  margin-top: 10px;
  text-align: left;
  flex-direction: column;
  .panel-lax-title {
    font-weight: 700;
    color: #00A9CE;
    font-size: medium;
  }
  .panel-lax-description {
    font-size: small;
    max-width: 100%;
  }
}

      
.panel-group-container {
  background-color: var(--panel-background-color);

  @media screen and (min-width: 768px) {
    margin-top: 3rem;
    padding: 3rem 0;
  }

  @media screen and (max-width: 1260px) {
    padding: 0 1rem;
    padding: 1rem 0;
  }

  .panel {
    &-group {
      position: relative;
      // max-width: 1260px;
      margin: 0 auto;
      // padding: 3rem 0;
    }

    &-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;

      padding: 0 1rem;

      // align-items: center;

      // @media screen and (max-width: 566.99px) {
      //   align-items: center;
      // }

      // @media screen and (min-width: 567px) {
      //   flex-direction: row;
      //   flex-wrap: wrap;
      // }

      // @media screen and (min-width: 960px) {
      //   flex-direction: row;
      //   flex-wrap: nowrap;
      // }
    }
  }

  .panel-group {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    min-height: 320px;
  }

  .panel-inner {
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 566.99px) {
      align-items: center;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 567px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 960px) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}
</style>

<style lang="scss">
// NWI Brand mods
.NWI {
  .panel-group-container {
    padding: 0;
    background: rgb(245, 245, 245);
    .panel-row-1 {
      padding: 3rem 0;
      @media screen and (max-width: 1320px) {
        padding: 3rem 1rem;
      }
      .panel.image-left {
        justify-content: flex-end;
      }
      .panel-image img {
        max-height: 220px;
      }
      .panel.image-right {
        max-width: 100%;
      }
    }
  }
}

// IP1 Brand mods
.IP1 {
  .panel-group-container {
    padding: 0;
    background: #fff;
    .panel-row-1 {
      background: rgb(245, 245, 245);
      padding: 3rem;
      .panel.image-left {
        justify-content: flex-end;
      }
      .panel-image img {
        max-height: 220px;
      }
    }
    .panel-row-2,
    .panel-row-3 {
      text-align: center;
      .panel.image-top {
        box-shadow: 0px 0px 0px #00000000;
      }
      .panel-image img {
        width: auto;
        max-height: 150px;
      }
      .panel-data {
        padding: 1rem;
      }
    }
    .panel-row-2 {
      padding-top: 3rem;
    }
    .panel-row-3 {
      padding-bottom: 3rem;
    }
  }
}

.PIT {
  .panel-group-container {
    padding: 0;
    background: #fff;
    
  }
}

.panel-group-pit {
  margin: 0 auto;
  margin-top: 1rem;
  max-width: 1320px;
  width: 100%;
  margin-bottom: 5rem;
  @media screen and (max-width: 786) {
    margin-top: 5rem;
    
  }
  @media screen and (max-width: 1260px) {
    padding: 0 1rem;
    padding: 1rem 0;
    max-width: calc(100% - 2rem);
    margin-top: 10px;
  }
}

.announcement-banner {
  width: 100%;
  margin-top: -25px;
  background-color: #f5c6cb;
  color: #721c24;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 1260px) {
    margin-top: 10px;
  }
}

@import "@/assets/sass/override_brand.scss";
</style>
