<template>
  <div class="footer_container">
    <div class="footer-wrapper-top">
      <div class="footer-inner-top">
        <div class="footer-links" :class="columns">
          <!-- <a href="/index.html">HOME</a> -->
          <div v-for="links in footer_links" :key="links.group">
            <div class="footer-links-heading">{{ links.group }}</div>
            <a
              v-bind:href="a.url"
              :key="a.mid"
              v-show="a.label !== ''"
              class="footer-links-main"
              :target="[
                a.mtype && a.mtype.toLowerCase() === 'external'
                  ? '_blank'
                  : '_self',
              ]"
              v-for="a in links.data"
              >{{ getrans(a.label) }}</a
            >
          </div>
        </div>
        <div class="footer-logo"></div>
      </div>
    </div>
    <div class="footer-wrapper-bottom">
      <div class="footer-inner-bottom">
        <span v-if="this.brandSlug == 'RDU'">
          © {{ new Date().getFullYear() }} RDUAA<br />
          {{ getrans("All Rights Reserved") }}.
        </span>
        <span v-else-if="this.brandSlug == 'YHM'">
          <b>John C. Munro Hamilton International Airport</b><br/>
          9300 Airport Road, Suite 2206<br/>
          Mount Hope, ON L0R 1W0<br/>
          Canada<br/>
          {{ getrans("Copyright ") }} © {{ getbrandname() }}
          {{ new Date().getFullYear() }}. {{ getrans("All Rights Reserved") }}.
          <br/>
          <br/>
          <br/>
          <br/>
        </span>
        <span v-else-if="this.brandSlug == 'SV1'">
          Copyright © {{ new Date().getFullYear() }} Summit Airport Parking
        </span>
        <span v-else-if="this.brandSlug == 'PIT'" class="footer-info">
          <b>Contact Information</b><br/>
          <b>LAZ Parking</b><br/>
          412-472-5050<br/>
          <a href="mailto:PIT@lazparking.com">PIT@lazparking.com</a><br/>
          {{ getrans("Copyright ") }} © {{ getbrandname() }}
          {{ new Date().getFullYear() }}. {{ getrans("All Rights Reserved") }}
        </span>
        <span v-else>
          {{ getrans("Copyright ") }} © {{ getbrandname() }}
          {{ new Date().getFullYear() }}. {{ getrans("All Rights Reserved") }}.
        </span>


        <span v-if="this.brandSlug !== 'PV1' && this.brandSlug !== 'SV1'">
          <template v-if="this.brandSlug === 'AB1' || this.brandSlug === 'RSW' || this.brandSlug === 'ICT' || this.brandSlug === 'LAX'">
            {{ getrans("Powered by ABM Vantage") }}
          </template>
          <template v-else>
            {{ getrans("Powered by") }}
            <a
              style="color: currentcolor"
              href="http://www.rezcomm.com/"
              target="_blank"
              >Rezcomm</a
            >
          </template>
        </span>

      </div>
    </div>
    <div style="clear: both"></div>
  </div>

  <div
    v-if="this.brandSlug !== 'none'"
    class="cookie-settings-float"
    :style="cookieBackgroundImage()"
    @click="toggle_cookie_settings()"
  >
    <span
      ><i class="fas fa-cookie-bite"></i
      ><span class="cookie-settings-text"
        >&nbsp;&nbsp;{{ getrans("Cookie settings") }}</span
      ></span
    >
  </div>
</template>

<script>
import md5 from "js-md5";
export default {
  name: "Footer",
  props: {
    footer_links: undefined,
    footer_columns: undefined,
    lang: undefined,
    translations: undefined,
    brandSlug: undefined,
  },
  computed: {
    columns: function () {
      return "grid-" + this.footer_columns.toLowerCase().replaceAll(" ", "-");
    },
  },
  data() {
    return {
      copyright: "Copyright © 2021. All rights reserved.",
      poweredby: "Powered by Rezcomm",
      airportname: "",
    };
  },
  methods: {
    getrans(string) {
      var s = md5(string);
      var ss = this.brandSlug + "-" + this.lang.toUpperCase() + "-" + s;
      //console.log(ss);
      return this.translations[ss] || string;
    },
    getbrandname() {
      if (this.brandSlug == "RDU") {
        return this.getrans("Raleigh Durham Airport");
      }
      if (this.brandSlug == "HER") {
        return this.getrans("Hermes Airport");
      }
      if (this.brandSlug == "YHM") {
        return this.getrans("Hamilton International Airport");
      }
      if (this.brandSlug == "OKC") {
        return this.getrans("Will Rogers World Airport");
      }
      if (this.brandSlug == "LAS") {
        return this.getrans("Clark County Department of Aviation");
      }
      if (this.brandSlug == "ICT") {
        return this.getrans("Wichita Airport Authority.");
      }
    },
    cookieBackgroundImage() {
      return `background-image: url(${require(`@/assets/images/cookie_option.svg`)});`;
    },
    toggle_cookie_settings() {
      const new_event = new CustomEvent("toggle_cookie_settings", {
        detail: {
          data: "toggle",
        },
      });
      window.dispatchEvent(new_event);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.footer_container {
  height: auto;
  position: relative;
}

.footer-wrapper-top,
.footer-wrapper-bottom {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
}

.footer-wrapper-top {
  border-top: 1px solid var(--footer_top_border_top_color);
  background-color: var(--footer_top_background_color, $footer-top-bg);
  color: var(--footer_top_text_color, $footer-top-text);
  font-family: var(--footer_top_font_family, $footer-top-font);
  font-size: var(--footer_top_font_size, 1rem);
  font-weight: var(--footer_top_font_weight, normal);
}

.footer-wrapper-bottom {
  border-top: 1px solid var(--footer_bottom_border_top_color);
  background-color: var(--footer_bottom_background_color, $footer-bottom-bg);
  color: var(--footer_bottom_text_color, $footer-bottom-text);
  font-family: var(--footer_bottom_font_family, $footer-bottom-font);
  font-size: var(--footer_bottom_font_size, 1rem);
  font-weight: var(--footer_bottom_font_weight, normal);
}

.footer-inner-top {
  max-width: calc(1280px + 2rem);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    align-items: center;
    padding-bottom: 2rem;
  }
  .footer-links {
    padding: 60px 0;
    display: grid;
    width: 100%;
    padding: 2rem 1rem 1rem;

    &.grid-1-columns {
      grid-template-columns: 1fr;
    }

    &.grid-2-columns {
      grid-template-columns: 1fr 1fr;
    }

    &.grid-3-columns {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.grid-4-columns {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    a {
      margin: 10px 0;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr !important;
      a {
        margin: 10px 0;
      }
    }

    &-heading {
      font-weight: 700;
    }

    &-main {
      display: inline-block;
    }
  }
  .footer-links a {
    color: var(--footer_top_text_color, #fff);
    text-decoration: none;
    padding: 0rem 2rem 2rem 0;
    &:hover {
      color: var(--footer_top_text_color_hover, --footer_top_text_color);
      text-decoration: underline;
    }
    @media screen and (max-width: 768px) {
      padding: 0 10px;
      width: 100%;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.footer-inner-bottom {
  padding: 30px 10px;
  max-width: calc(1280px + 2rem);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 30px 30px;
    span {
      padding: 20px 0;
    }
  }
}

.footer-logo {
  width: var(--footer_logo_width);
  height: var(--footer_logo_height);
  background-image: var(--footer_logo);
  background-size: contain;
  background-repeat: no-repeat;
  align-items: center;
  display: var(--footer_logo_active, none);
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: var(--footer_logo_width);
  }
}

.cookie-settings {
  &-float {
    position: fixed;
    bottom: 2rem;
    left: 0;
    padding: 10px 20px;
    border-top: none;
    border-left: none;
    cursor: pointer;
    z-index: 99999999;
    display: flex;
    flex-wrap: nowrap;
    align-self: flex-start;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 768px) {
      bottom: 0rem;
      font-size: 1.25rem;
    }

    span {
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      color: #fff;
    }
  }

  &-text {
    color: #fff;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
</style>
