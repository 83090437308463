<template>
  <div class="results-banner">
    <div class="results-banner__heading">
      <h1>{{ headline }}</h1>
      <p>
        {{ subheadline }}
      </p>
    </div>
    <div class="slot-filters">
      <slot name="filters"></slot>
      <slot name="widget"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultsBanner",
  props: {
    headline: undefined,
    subheadline: undefined,
    editLabel: undefined,
  },
  computed: {},
  data() {
    return { showSearchWidget: false };
  },
  methods: {},
  updated() {},
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.search-widget-results {
  display: block;
  width: 100%;
}

.results-banner {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  @media screen and (min-width: 1261px) and (max-width: 1340px) {
    padding: 0 1rem;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__heading {
    color: #ffffff;

    h1 {
      font-size: 1.5rem;
      font-weight: 700;
    }

    p {
      font-size: 0.8rem;
      font-weight: 600;
    }

    @media screen and (min-width: 768px) {
      h1 {
        font-size: 1.625rem;
        margin-top: 0;
      }

      p {
        font-size: 1.375rem;
        margin-bottom: 0;
      }
    }
  }

  &__edit-button {
    display: none;
    @media screen and (min-width: 768px) {
      display: initial
    }
    position: relative;
    a {
      width: 105px;
      line-height: 35px;
      background-color: #e8e8e8;
      box-shadow: 0px 3px 6px #00000029;
      display: inline-block;
      text-align: center;
      color: #000000;
      font-weight: 700;
      font-size: 0.875rem;
    }
  }
}

.slot-filters {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;


}
</style>
